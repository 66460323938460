import React from "react"
import Slideshow from "../components/bb/slideshow"
import Table from "../components/bb/table"
import { css } from "@emotion/react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/bb/button"
// import { Link } from "gatsby"

let amenities = [
  ["Bathtub", "Hair dryer", "Hot water"],
  ["Towels, soap, and toilet paper","Hangers","Bed linens"],
  ["Indoor fireplace", "Air conditioning", "Heating"],
  ["External security cameras on property", "Fire extinguisher", "Smoke/Carbon monoxide alarm"],
  ["TV", "Wifi", "Kitchen"],
  ["Coffee maker", "Microwave", "Refrigerator"],
  ["Oven","Dishes", "Silverware"],
  ["Garden/Yard", "BBQ grill", "Patio/balcony"],
  ["Free parking on site", "Long term stays allowed", "Building Staff"],
];
// append green checkmark infront of each
amenities = amenities.map((el) =>{
  return el.map((e)=>{
    return (
    <div>
      <span css={css`
        color: var(--secondary);
        font-weight: bolder;
        text-shadow: rgba(var(--secondaryalt), 0.75) 1px 2px 4px;
      `}>✔ </span>
      {e}
    </div>)
  })
});

export default function CabinOne() {

  const bookButton = (
    <Button
      value="Book on Airbnb"
      href="https://www.airbnb.com/rooms/46315752?#availability-calendar"
      customstyle='padding: 24px 36px; margin: 24px 0;'
    />
  );
 
  return(
    <Layout>
      <SEO title="The Expansive Retreat" />
      <h1>An Expansive Retreat on Crooked Creek</h1>
      
      <Slideshow imagefilter='cabin1' />
      
      {bookButton}
      
      <div id="section1">
        <h2>Property Description</h2>

        <p>Our Dry Hollow Retreat is a 5 bedroom, 2.5 bath lodge with a wrap around deck situated on Crooked Creek which is a Class 1 trout stream. 
          Sitting on 450 acres with 3 adjoining cabins also available for large group gatherings. Deer Bow hunting and turkey hunting packages are 
          also offered but nature lovers and city escapees always welcome as well! Sit and relax by our firepit and enjoy the magnificent view of the 
          stars above while listening to the creek close by.</p>

        <h3>Sleeping arrangements</h3>
        <p>13 guests · 5 bedrooms · 6 beds · 2.5 baths</p>
        
        <h3>Guest access</h3>
        <p>The entire cabin is for your enjoyment as well as the 450 acres it sits on. We have trails throughout.</p>
        <h3>Other things to note</h3>
        <p>Guests to bring personal towels and soaps. Sheets and bedding provided and cleaned before and after stay.
            Knives, forks, plates, coffee maker, a pan &amp; a pot are provided. Feel free to bring any additional items needed to prepare your meals.
            Due to Covid, all items are disposed of upon departure so please bring whatever you need. There are grocery stores within a 10 minute drive.
            There is a fire pit outside, bring your firewood &amp; marshmallows and you will be good to go.
            Check out is 11 AM to allow ample time for cleaning.
        </p>
      </div>
      <div id="amenities">
        <h2>amenities</h2>
        <Table data={amenities} />
      </div>

      {bookButton}

  </Layout>   
  )
}
